<template>
  <vs-card>
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <vs-input
          :success="!errors.first('basic.name') && formData.name!=''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="(errors.first('basic.name') ? true : false)"
          v-validate="'required'"
          name="name"
          data-vv-as="name"
          label="Name"
          class="w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.name')"
          v-model="formData.name"
        />
      </div>

    </div>
    <vs-button color="success" class="ml-auto mt-4" @click="saveData"><span v-if="update">Update Applicable Area</span><span v-else >Add Applicable Area</span> </vs-button>

  </vs-card>
</template>

<script>
  export default {
    name: 'ApplicableAreaForm',
    props:['detail','update'],
    data() {
      return {
        removeIndex:0,
        formData: this.detail,
      };
    },
    methods: {
      async saveData() {
        let isValid = await this.$validator.validateAll('basic');
        if (!isValid) {
          this.$vs.notify({
            title: 'Error',
            text: 'Validation Failed',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
          return false;
        }
        this.$emit('saveDetail', this.formData);
      },
    }
  };
</script>

<style scoped>

</style>
