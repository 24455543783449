<template>
  <ApplicableAreaForm @saveDetail="saveDetail" :detail="detail" :update="false"/>

</template>

<script>
  import ApplicableAreaForm from "../../components/applicable-area/ApplicableAreaForm";
  import { mapActions } from 'vuex';
  export default {
    name: 'AddApplicableArea',
    components:{
      ApplicableAreaForm
    },
    data: () => ({
      detail: {
        name: '',
      }
    }),
    methods:{
      ...mapActions("product", ["saveNewApplicableArea"]),

      saveDetail(data){
        let self=this;
        this.saveNewApplicableArea(data).then(res => {
          self.$vs.notify({
            title: "Applicable Area Add",
            text: "Applicable Area added successfully",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push({ name: 'ApplicableAreaManagement'})
        }).catch(err => {
          self.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: 'danger'
          });
          self.$vs.loading.close();
        });
      },
    }
  };
</script>

<style scoped>

</style>
